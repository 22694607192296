<div style="margin-left: 15px;" class="div-27">{{placeHolders['LBL_TITLE_RETARGETING']}}</div>
<p style="margin-left: 15px;" class="pt-2">({{placeHolders['LBL_DESC_SEND_BETTER_OFFER']}})
</p>

<div class="filter-container col-3">
  <label style="width: 75%; " for="marketPlaceFilter">{{placeHolders['LBL_FILTER_BY_STATUS']}}:</label>
  <select id="marketPlaceFilter" class="custom-select" (change)="applyFilter($event)">
      <option value="A">{{placeHolders['LBL_ACTIVE']}}</option>
      <option value="E">{{placeHolders['LBL_EXPIRED']}}</option>
      <option value="">{{placeHolders['LBL_ALL']}}</option>
      <!-- <option *ngFor="let name of uniqueMarketPlaceNames" [value]="name">{{ name }}</option> -->
  </select>
</div>
<div class="table-container mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Deal Id Column -->
    <ng-container matColumnDef="dealId">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_DEAL_ID']}}</th>
      <td mat-cell *matCellDef="let element"> {{element.dealId}} </td>
    </ng-container>

    <!-- Deal Title Column -->
    <ng-container matColumnDef="dealTitle">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_DEAL_TITLE']}}</th>
      <td mat-cell *matCellDef="let element"> {{element.dealTitle}} </td>
    </ng-container>

    <!-- Valid To Column -->
    <ng-container matColumnDef="validTo">
      <th mat-header-cell *matHeaderCellDef style="width: 12%;"> {{placeHolders['LBL_TBL_VALID_TO']}} </th>
      <td mat-cell *matCellDef="let element"> {{element.validTo | date: 'dd-MM-yyyy'}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_STATUS']}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-chip [ngClass]="getStatusClass(element.status)">
          {{getStatusText(element.status)}}
        </mat-chip>
      </td>
    </ng-container>

    <!-- Current High Prospects Column -->
    <ng-container matColumnDef="currentHighProspects">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_CURRENT_HIGH_PROSPECT']}} </th>
      <td mat-cell *matCellDef="let element"> {{element.currentHighProspects}} </td>
    </ng-container>

    <!-- Messages Sent Column -->
    <ng-container matColumnDef="messagesSent">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_MESSAGE_SENT']}}  </th>
      <td mat-cell *matCellDef="let element"> {{element.messagesSent}} </td>
    </ng-container>

    <!-- Current Pending Prospects Column -->
    <ng-container matColumnDef="currentPendingProspects">
      <th mat-header-cell *matHeaderCellDef> {{placeHolders['LBL_TBL_PENDING_PROSPECTS']}}</th>
      <td mat-cell *matCellDef="let element"> {{element.currentPendingProspects}} </td>
    </ng-container>

    <!-- Total Spent Column -->
    <ng-container matColumnDef="totalSpent">
      <th mat-header-cell *matHeaderCellDef>{{placeHolders['LBL_TBL_TOTAL_SPENT']}}</th>
      <td mat-cell *matCellDef="let element"> {{element.totalSpent}} </td>
    </ng-container>

    <!-- Row definition -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor: pointer;" (click)="onRowClicked(row)">
    </tr>
  </table>
  <!--   
    <mat-paginator [length]="recordTotalCount"
                   [pageSize]="5"
                   [pageSizeOptions]="[5, 10, 20]">
    </mat-paginator> -->

</div>
<div class="pt-2">
  <app-pagination [totalRecords]="totalCount" [recordsPerPage]="pageSize" [activePage]="activePage"
    (onPageChange)="onPageChange($event)"></app-pagination>
</div>