// import { Component } from '@angular/core';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// @Component({
//   selector: 'app-dealcreation',
//   templateUrl: './dealcreation.component.html',
//   styleUrl: './dealcreation.component.scss'
// })
// export class DealcreationComponent {
//   dealForm: FormGroup;
//   dealCategories = ['Category 1', 'Category 2', 'Category 3'];
//   discountTypes = ['Percentage', 'Flat'];

//   constructor(private fb: FormBuilder) {
//     this.dealForm = this.fb.group({
//       dealTitle: ['', [Validators.required, Validators.maxLength(50)]],
//       dealCategory: ['', Validators.required],
//       discountCode: ['', [Validators.required, Validators.maxLength(10)]],
//       discountType: ['', Validators.required],
//       discountDescription: ['', [Validators.maxLength(100)]]
//     });
//   }

//   get dealTitle() {
//     return this.dealForm.get('dealTitle');
//   }

//   get discountCode() {
//     return this.dealForm.get('discountCode');
//   }

//   get discountDescription() {
//     return this.dealForm.get('discountDescription');
//   }

//   onSubmit() {
//     if (this.dealForm.valid) {
//       console.log('Form Submitted', this.dealForm.value);
      
//     }
//   }
// }




import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dealcreation',
    templateUrl: './dealcreation.component.html',
    styleUrl: './dealcreation.component.scss'
})
export class DealcreationComponent {
  currentStep = 1; // Start with the first step active
  dealForm: FormGroup;
  dealCategories = ['Category 1', 'Category 2', 'Category 3'];
  discountTypes = ['Percentage', 'Flat'];

  constructor(private fb: FormBuilder) {
    this.dealForm = this.fb.group({
      // Deal Details Form Fields
      dealTitle: ['', [Validators.required, Validators.maxLength(50)]],
      dealCategory: ['', Validators.required],
      discountCode: ['', [Validators.required, Validators.maxLength(10)]],
      discountType: ['', Validators.required],
      discountDescription: ['', [Validators.maxLength(500)]],
      // Additional form controls for other steps can be added here
    });
  }

  // Helper getter methods for easy access to form controls
  get dealTitle() {
    return this.dealForm.get('dealTitle');
  }

  get discountCode() {
    return this.dealForm.get('discountCode');
  }

  get discountDescription() {
    return this.dealForm.get('discountDescription');
  }

  setStep(step: number) {
    this.currentStep = step;
  }

  onSubmit() {
    if (this.dealForm.valid) {
      console.log('Form Submitted', this.dealForm.value);
      // Handle form submission (e.g., send to API)
    }
  }
}

