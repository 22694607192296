import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationAlertService } from '../services/notification-alert.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    placeholders = environment.placeholders as { [key: string]: string };
    placeHolders: any;
    popUpMsg:any;
  constructor(private router: Router,private notifyService:NotificationAlertService) {
  
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
  
    const isMenuactive = sessionStorage.getItem('isDashboardActive');
    if (isMenuactive === 'true') {
      return true; // Allow navigation
    } else {
      
      this.popUpMsg = this.placeholders['Pop_Up_Msg']
      const message = isMenuactive === null ? 'Please login again' :'Please complete your profile'
      // Redirect to a different route or show an error message
      this.notifyService.showError(message, "ERROR");
      return false//// Example: Redirect to login page
    }
  }
}
