import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import Validation from 'src/app/utils/validation';

@Component({
    selector: 'app-user-roles',
    templateUrl: './user-roles.component.html',
    styleUrl: './user-roles.component.scss'
  })
export class UserRolesComponent {
  verifiedPhone = false; // false
  disablePhone = false; // false
  phoneOtpField = false; // false
  phoneVerified = false;  //false
  verifiedEmail = true;
  showCreatePass = false;
  showConfirmPass = false;
 
  disableEmail = true;
  countryData: any;
  country: any;
  macAddress: any;
  emailOtpField = true;
 
  verifyEmailField = true;
  verifyPhoneField = true;
  emailVerified = true;
 
  resendEmailOtp: boolean = false;
  resendMobileOtp: boolean = false;
  displayEmailTimer: boolean = false;
  displayMobileTimer: boolean = false;
  displayEmail: any;
  displayMobile: any;
  // disable = false;
  userId:any;
  administartiveForm: FormGroup = new FormGroup({
    primaryContact: new FormControl(''),
    role: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });

  constructor(private router: Router,private route: ActivatedRoute, private fb: FormBuilder, private notifyService: NotificationAlertService,
    private confirmationDialogService: ConfirmationDialogService, private service: CommonUtilService, private cStorage: ClientSideStorageService,) {
      this.macAddress = Math.random();
        this.cStorage.set('country', JSON.stringify([]));
        this.cStorage.set('macAddress', JSON.stringify(this.macAddress));
        this.country = JSON.parse(this.cStorage.get("country"));
        this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
    //   this.countryData = res.body?.data;
    // });
    this.createForm();
    this.fillFormWithUrlParams();
    this.administartiveForm.get('password')?.valueChanges.subscribe(value => {
      this.service.setPasswordRulesDataData(value);
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  createForm() {
    this.administartiveForm = this.fb.group({
      primaryContact: ['', [Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)]],
      role: [''],
      email: ['', [Validators.required, Validators.email]],
      emailotp: ['', []],
      phone: ['', Validators.required],
      phoneotp: [''],
      accept: [''],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  private fillFormWithUrlParams(): void {
        this.route.queryParams.subscribe(params => {
          console.log(params)
          if (params['firstName']) {
            let fullname = params['firstName']+" "+ params['lastName']
            this.administartiveForm.patchValue({ primaryContact: fullname });
          }
          if (params['role_name']) {
            this.administartiveForm.patchValue({ role: params['role_name'] });
          }
          if (params['email']) {
            this.administartiveForm.patchValue({ email: params['email'] });
          }
          if (params['mobile']) {
            this.administartiveForm.patchValue({ phone: params['mobile'] });
          }
          if (params['person_id']) {
            this.userId = params['person_id']
          }
          console.log(this.userId)
        });

        this.administartiveForm.controls['email'].disable();
        this.administartiveForm.controls['role'].disable();
        this.administartiveForm.controls['primaryContact'].disable();

      }

  sendEmailOtp() {
    let status: any = this.administartiveForm.controls['email'].status;
    if (status !== 'INVALID') {
      let payload = {
        email: this.administartiveForm.value.email
      }
      this.service.sendEmailOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        this.emailOtpField = true;
        this.verifyEmailField = false;        
        this.startEmailTimer(1);
      });
    } else {
      this.notifyService.showError(
        "Invalid Email!!",
        "Error"
      );
    }
  }

  startEmailTimer(minute: any) {
    this.displayEmailTimer = true;
    this.resendEmailOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayEmail = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendEmailOtp = true;
        this.displayEmailTimer = false;
      }
    }, 1000);
  }

  validateOtpEmail() {
    let status: any = this.administartiveForm.controls['emailotp'].status;
    if (status !== 'INVALID') {
      let payload = {
        "email": this.administartiveForm.value.email,
        "type": "email",
        "validationCode": this.administartiveForm.value.emailotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.verifiedEmail = true;
          this.notifyService.showSuccess(
            "Email Verified !!",
            "Success"
          );
          this.verifyEmailField = true;
          this.disableEmail = true;
          this.emailVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }

    //   if(this.administartiveForm.invalid) {
    //     return;
    //   } else{
    //     this.showPhone =true;
    //     this.disable = true;
    //   }
  }


  onOtpEmailChange(event: any) {
    let res = event;
    this.administartiveForm.value.emailotp = res;
  }

  sendMobileOtp() {
    let status: any = this.administartiveForm.controls['phone'].status;
    if (status !== 'INVALID') {
      let payload = {
        mobileNumber: this.administartiveForm.value?.phone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if(res.body?.status === "ERROR"){
          this.notifyService.showError(
            res.body?.message,
            "Error"
          );
        }
        else{
          let value = res;
          this.phoneOtpField = true;
          this.verifyPhoneField = false;
          this.startMobileTimer(1);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Phone Number !!",
        "Error"
      );
    }
  }

  startMobileTimer(minute: any) {
    this.displayMobileTimer = true;
    this.resendMobileOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayMobile = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendMobileOtp = true;
        this.displayMobileTimer = false;
      }
    }, 1000);
  }

  onOtpPhoneChange(event: any) {
    let res = event;
    this.administartiveForm.value.phoneotp = res;
  }

  validateOtpPhone() {
    let status: any = this.administartiveForm.controls['phoneotp'].status;
    if (status !== 'INVALID') {
      let payload = {
        "phoneNumber": this.administartiveForm.value.phone,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.phoneotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.verifiedPhone = true;
          this.notifyService.showSuccess(
            "Phone Number Verified !!",
            "Success"
          );
          this.verifyPhoneField = true;
          this.disablePhone = true;
          this.phoneVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  reset() {
    this.administartiveForm.reset();
  }


  save() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to submit? ")
      .then((confirmed) => {
        if (confirmed) {
          let value = {
            "personId": parseInt(this.userId),
            "password": this.administartiveForm.value.password,
            "mobile": this.administartiveForm.value.phone,
            // "oldPassword":"password@123"
          }
          this.service.adminUpdatePassword(value,this.country, this.macAddress).subscribe((res: any) => {
            if (res.status === "ERROR") {
              this.notifyService.showError(
                "User Alredy Registered !!",
                res.status
              );
            } else {
              this.notifyService.showSuccess(
                "User Registered Successfully !!",
                "Success"
              );
              this.router.navigate(["/"]);
            }
          });
        }
      });
  }


  // save() {
  //   debugger;
  //   console.log(this.administartiveForm)
  //   if (this.administartiveForm.status === "VALID") {
  //     const fullName = this.administartiveForm.value.primaryContact.split(' ');
  //     const lastName = fullName.pop(); // 'Panakkal'
  //     const firstName = fullName.join(' ')
  //     console.log(fullName,lastName,firstName);
  //     let payload = {
  //       "password": this.administartiveForm.value.password,
  //       "firstName": firstName,
  //       "lastName": lastName,
  //       "email_id": this.administartiveForm.value.email,
  //       "roleId": 1
  //     }
  //     this.confirmationDialogService
  //       .confirm("Confirmation ", "Are you sure you want to save? ")
  //       .then((confirmed) => {
  //         if (confirmed) {
  //           this.service.adminSignup(payload, this.country, this.macAddress).subscribe((res: any) => {
  //             if (res.body?.status === 'Error') {
  //               this.notifyService.showError(
  //                 res.body?.message,
  //                 res.body?.status
  //               );
  //             } else {
  //               const data ={
  //                 emailId: this.administartiveForm.value.email,
  //                 password: this.administartiveForm.value.password
  //               }
  //               this.notifyService.showSuccess(
  //                 "User Registered Successfully",
  //                 res.body?.status
  //               );
  //               this.service.setLoginData(data);
  //               this.cStorage.set('userId', JSON.stringify(res.body?.data.personId));
  //               this.cStorage.set('userName', JSON.stringify(res.body?.data.email_id));
  //               this.cStorage.set('roleId', JSON.stringify(res.body?.data.roleId));
  //               this.router.navigate(["/login"]);

  //             }

  //           });
  //         }
  //       });
  //   } else {
  //     this.notifyService.showError(
  //       "Please fill all the mandatory details !!",
  //       "Error"
  //     );
  //   }
  // }
}
