// import { CanDeactivateFn } from '@angular/router';
// import { Observable } from 'rxjs';

// export interface CanComponentDeactivate {
//   canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
// }

// // Define the CanDeactivate guard function
// export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (
//   component,
//   currentRoute,
//   currentState,
//   nextState
// ) => {
//   return component.canDeactivate ? component.canDeactivate() : true;
// };




import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../services/common-util.service';
import { inject } from '@angular/core';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const canDeactivateGuard: CanDeactivateFn<CanComponentDeactivate> = (
  component,
  currentRoute,
  currentState,
  nextState
) => {
  const navigationService = inject(NavigationService); // Inject the navigation service

  if (navigationService.programmaticNavigation) {
    navigationService.programmaticNavigation = false; // Reset flag after use
    return true; // Bypass the guard
  }
  
  return component.canDeactivate ? component.canDeactivate() : true;
};
