<div (click)="edit($event)"> <!--Edit class="button mt-2" -->
    <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/567bd4dd-2799-47b6-976f-c9a71011b766?"
        class="img-16" width="20px"/> -->
    <div class="tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 64 64">
            <path fill="#36849d"
                d="m59.466 6.317l-1.783-1.784a8.654 8.654 0 0 0-12.234 0L9.419 40.565L2.278 59.237c-.838 2.21.281 3.323 2.484 2.481l18.675-7.134l36.029-36.033c3.381-3.376 3.377-8.853 0-12.234m-22.629 9.486l2.508 2.507l-24.764 24.766l-2.508-2.509zm3.834 3.834l3.695 3.696l-24.763 24.765l-3.695-3.695zM11.026 57.32l-4.342-4.346l4.201-10.941L21.964 53.11zm12.411-5.387l-2.506-2.509l24.762-24.765l2.508 2.509zm26.209-26.21L38.281 14.36l2.827-2.828l11.365 11.363z" />
        </svg>
        <span class="tooltiptext">{{placeHolders['LBL_EDIT']}}</span>
    </div>
</div>