<form [formGroup]="administartiveForm">
  <div class="div">
    <div class="div-2">
      <div class="div-3">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/12819099-6939-4a45-a53d-6fada6f7c721?"
          class="img-2" />
      </div>

    </div>
    <div class="div-11">
      <div class="div-12">Please confirm your details </div>

      <div class="div-14">
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/feb2790cc915f00c86f8d2409120b6eccf959f9498ec3d541b901a552e92072c?"
          class="img-4" />
        <div class="div-15">Email address verified successfully</div>
        <div class="div-16">
          Please confirm the below details to complete registration
        </div>
      </div>
      <div class="div-19">
        <div class="div-20">
          <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5aae1765-fb77-4389-84a6-cd1335f82f1d?"
          class="img-5" /> -->

          <!-- <a class="div-21" routerLink="/register">Back to Company Details</a> -->
          <!-- <div class="div-21">Back to Company Details</div> -->
        </div>
        <div class="row col-12 col-md-12 pt-3">
          <div class="col-4 form-group">
            <label class="asterix" for="primarycontact">Name</label>
            <input readonly type="text" formControlName="primaryContact" class="form-control" placeholder="First name"
              pattern="^[A-Za-z ]+$" maxlength="30" (keydown.space)="$event.preventDefault();" required />
          </div>
          <div class="col-4 form-group">
            <label class="asterix" for="primarycontact">Role</label>
            <input readonly type="text" formControlName="role" class="form-control" placeholder="Role"
              pattern="^[A-Za-z ]+$" maxlength="30" (keydown.space)="$event.preventDefault();" required />
          </div>
        </div>
        <!--Email Section Field Section -->

        <div class="row col-12 col-md-12 pt-3">
          <div class="col-4 form-group">
            <label class="asterix" for="email">Email</label>
            <input type="email" class="form-control" formControlName="email" placeholder="Email"
              [ngClass]="{ 'is-invalid':  f['email'].errors && (f['email'].dirty || f['email'].touched ) }"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" [readonly]="emailVerified" required />
            <small>(Prefer business email)</small>
            @if ( f['email'].errors) {
            <div class="invalid-feedback">
              @if (f['email'].errors['required']) {
              <div>Email is required</div>
              }
              @if (f['email'].errors['pattern']) {
              <div>Invalid email</div>
              }
            </div>
            }
          </div>
          <img *ngIf="emailVerified===true" loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e77607a-df82-4ba3-a07e-abde78fac171?"
            class="img-succes-tick col-1" />


          <div class="col-4 form-group">
            <label class="asterix" for="phonenumber">Primary contact number</label>
            <input type="number"
              onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
              class="form-control" formControlName="phone" placeholder="Mobile number"
              [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }" required
              pattern="^[0-9]{12}$" [readonly]="phoneVerified" onKeyPress="if(this.value.length==12) return false;" />
            @if ( f['phone'].errors) {
            <div class="invalid-feedback">
              @if (f['phone'].errors['required']) {
              <div>Mobile number is required</div>
              }
              @if (f['phone'].errors['pattern']) {
              <div>Enter 10 digit Mobile number</div>
              }
            </div>
            }
          </div>

          <img *ngIf="phoneVerified===true" loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e77607a-df82-4ba3-a07e-abde78fac171?"
            class="img-succes-tick col-1" />
          <div class="col-1">
            <button class="div-37" *ngIf="phoneOtpField===false" (click)="sendMobileOtp()">Verify
              mobile</button>
          </div>

        </div>

        <div class="row col-12 col-md-12 pt-2" *ngIf="verifyEmailField===false && resendEmailOtp !== true">
          <div class="col-5 form-group">
            <label>Enter email OTP</label>
            <ng-otp-input formControlName="emailotp" (onInputChange)="onOtpEmailChange($event)" [config]="{length:6}"
              ngDefaultControl></ng-otp-input>
          </div>
          <div class="col-2">
            <button class="div-37 submitOtp" (click)="validateOtpEmail()">Submit</button>
          </div>
        </div>

        <div class="row col-12 col-md-12">
          <div *ngIf="verifyEmailField===false" class="px-2" style="text-align: left;color: gray;">
            <p *ngIf="displayEmailTimer">Expiring in <span style="color: red;">{{ displayEmail
                }}</span></p>
            <a *ngIf="resendEmailOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
              (click)="sendEmailOtp()">Resend email OTP</a>
          </div>
        </div>

        <!--Phone Section Field Section -->


        <!----Phone -->
        <div class="row col-12 col-md-12" *ngIf="verifyPhoneField===false && resendMobileOtp !== true">
          <div class="col-5 form-group">

            <label>Enter mobile OTP</label>
            <ng-otp-input formControlName="phoneotp" (onInputChange)="onOtpPhoneChange($event)" [config]="{length:6}"
              ngDefaultControl></ng-otp-input>
          </div>
          <div class="col-2">
            <button class="div-37 submitOtp" (click)="validateOtpPhone()">Submit</button>
          </div>
        </div>
        <div class="row col-12 col-md-12">
          <div *ngIf="verifyPhoneField===false" class="px-2" style="text-align: left;color: gray;">
            <p *ngIf="displayMobileTimer">Expiring in <span style="color: red;">{{ displayMobile
                }}</span></p>
            <a *ngIf="resendMobileOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
              (click)="sendMobileOtp()">Resend mobile OTP</a>
          </div>
        </div>




        <div class="div-set35" *ngIf="emailVerified===true && phoneVerified===true">Your password</div>
      
          <div class="row col-12 col-md-12 pt-3" *ngIf="emailVerified===true && phoneVerified===true">
            <div class="col-3 form-group">
              <label for="password" class="asterix">Password</label>
              <input matInput [type]="showCreatePass ? 'text' : 'password'" class="form-control"
                placeholder="Create password" formControlName="password"
                pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched )  }"
                required />
              <!-- @if (f['password'].errors) {
                    <div class="invalid-feedback">
                        @if (f['password'].errors['required']) {
                        <div>Password is required</div>
                        }
                        @if (f['password'].errors['minlength']) {
                        <div>Password must be at least 8 characters</div>
                        }
                        @if (f['password'].errors['maxlength']) {
                        <div>Password must not exceed 30 characters</div>
                        }
                        @if (f['password'].errors['pattern']) {
                        <div>
                            Enter correct pattern
                        </div>
                        }
                    </div>
                    } -->
            </div>
            <mat-icon matSuffix class="col-1" style="overflow: visible;margin-top: 30px;margin-left: -85px"
              (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>
            <div class="col-1"></div>
            <app-password-rules style="margin-left: 22rem;width: 70%;"></app-password-rules>
          </div>
        
        
          <div class="row col-12 col-md-12 pt-3" style="margin-top: -5rem;" *ngIf="emailVerified===true && phoneVerified===true">
            <div class="col-3 form-group">
              <label for="password" class="asterix">Confirm password</label>
              <input matInput [type]="showConfirmPass ? 'text' : 'password'" class="form-control"
                placeholder="Re-enter password" formControlName="confirmPass"
                [ngClass]="{ 'is-invalid': !f['password'].errors && f['confirmPass'].errors && (f['confirmPass'].dirty || f['confirmPass'].touched ) }"
                required />
              @if ( f['confirmPass'].errors) {
              <div class="invalid-feedback">
                <!-- @if (f['confirmPass'].errors['required']) {
                        <div>Confirm password is required</div>
                        } -->
                @if (f['confirmPass'].errors['matching']) {
                <div>Confirm password does not match</div>
                }
              </div>
              }
            </div>
            <mat-icon matSuffix class="col-1 " style="overflow: visible;margin-top: 30px;margin-left: -85px"
              (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
          </div>
        





        <!-- <div class="div-80" c>
          <input type="checkbox" formControlName="accept" />
          <div class="div-81">
            <span style="color: rgba(49, 67, 69, 1)">I Accept</span>
            <span style="color: rgba(54, 132, 157, 1)">
              Terms & Conditions
            </span>
            <span style="color: rgba(49, 67, 69, 1)">& </span>
            <span style="color: rgba(54, 132, 157, 1)">Privacy Policy</span>
          </div>
        </div> -->



        <div class="mt-3" style="align-self: end;" *ngIf="emailVerified===true && phoneVerified===true">

          <!-- <nav>
            <button style="border: none;background: none;" (click)="reset()" class="button">
              <div class="div-47">Reset</div>
            </button>
          </nav> -->
          <div>
            <button (click)="save()" class="btn div-63">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>