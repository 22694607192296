import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import {} from '../../assets/placeholder-texts.json'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private strapiUrl = environment.strapiUrl; // Replace with your Strapi API URL
  private defaultLocale = 'en'; // Default locale for placeholder texts
  private localeKey = 'userLocale';
  private cacheKey = 'translations';
  private cacheExpirationKey = 'translationsExpiration';
  private cacheDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  private jsonUrl = '../../assets/placeholder-texts.json';
  private placeholderTextsSubject = new BehaviorSubject<any>(null);
  placeholderTexts$: Observable<any> = this.placeholderTextsSubject.asObservable();

  constructor(private http: HttpClient) {}

  private getDefaultPlaceholders(): Observable<any> {
    return this.http.get<any>('assets/placeholder-texts.json');
  }

  private getTranslations(locale: string): Observable<any> {
    // return this.http.get<any>(`${this.apiUrl}/content?locale=${locale}`);
    return this.http.get<any>(`${this.strapiUrl}/all?locale=${locale}&populate=*`);
    // return this.http.get<any>(this.jsonUrl);
  }

  private loadFromCache(): any {
    const cache = localStorage.getItem(this.cacheKey);
    const expiration = localStorage.getItem(this.cacheExpirationKey);
    if (cache && expiration && new Date().getTime() < parseInt(expiration, 10)) {
      return JSON.parse(cache);
    }
    return null;
  }

  private saveToCache(data: any): void {
    localStorage.setItem(this.cacheKey, JSON.stringify(data));
    localStorage.setItem(this.cacheExpirationKey, (new Date().getTime() + this.cacheDuration).toString());
  }

  private getUserLocale(): string {
    return localStorage.getItem(this.localeKey) || this.defaultLocale;
  }

  setUserLocale(locale: string): void {
    localStorage.setItem(this.localeKey, locale);
  }

  initializePlaceholders(): Observable<any> {
    const locale = this.getUserLocale();
    const cachedTranslations = this.loadFromCache();
    if (cachedTranslations) {
      this.placeholderTextsSubject.next(cachedTranslations);
      return new Observable((observer) => {
        observer.next(cachedTranslations);
        observer.complete();
      });
    } else {
      return this.getDefaultPlaceholders().pipe(
        tap((defaultPlaceholders) => {
          this.placeholderTextsSubject.next(defaultPlaceholders);
        }),
        map((defaultPlaceholders) => ({
          defaultPlaceholders,
          locale,
        })),
        switchMap(({ defaultPlaceholders, locale }) =>
          this.getTranslations(locale).pipe(
            map(response => response.data.attributes),
            map(translations => ({
              ...defaultPlaceholders,
              ...translations,
            })),
            tap((mergedTexts) => {
              this.placeholderTextsSubject.next(mergedTexts);
              this.saveToCache(mergedTexts);
            })
          )
        )
      );
    }
  }

  changeLocale(locale: string): void {
    this.setUserLocale(locale);
    this.getTranslations(locale).pipe(
      map(response => response.data.attributes),
      map(translations => ({
        ...this.placeholderTextsSubject.getValue(),
        ...translations,
      })),
      tap((updatedTexts) => {
        this.placeholderTextsSubject.next(updatedTexts);
        this.saveToCache(updatedTexts);
        window.location.reload();
      })
    ).subscribe();
  }
}
