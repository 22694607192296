import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AdministatordetailsComponent } from './views/register/administatordetails/administatordetails.component';
import { AdministatorpwdsetComponent } from './views/register/administatorpwdset/administatorpwdset.component';
import { CampaignComponent } from './views/campaign/campaign.component';
import { TragetaudienceComponent } from './views/campaign/tragetaudience/tragetaudience.component';
import { BudgetdetailsComponent } from './views/campaign/budgetdetails/budgetdetails.component';
import { ValidstatusComponent } from './views/campaign/validstatus/validstatus.component';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { ReportComponent } from './views/report/report.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { FilterReportComponent } from './views/report/filter-report/filter-report.component';
import { DetailedReportComponent } from './views/report/detailed-report/detailed-report.component';
import { MessagingComponent } from './views/messaging/messaging.component';
import { SelectUsersComponent } from './views/messaging/favourites/select-users/select-users.component';
import { BirthdayComponent } from './views/messaging/birthday/birthday.component';
import { FestiveComponent } from './views/messaging/festive/festive.component';
import { ComposeMessageComponent } from './views/messaging/birthday/compose-message/compose-message.component';
import { ComposeMessageFestiveComponent } from './views/messaging/festive/compose-message-festive/compose-message-festive.component';
import { UserRolesComponent } from './views/user-roles/user-roles.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';
import { CreatePasswordComponent } from './views/login/create-password/create-password.component';
import { CompanyProfileComponent } from './views/company-profile/company-profile.component';
import { AdministratorDetailsComponent } from './views/company-profile/administrator-details/administrator-details.component';
import { ChangePasswordComponent } from './views/company-profile/change-password/change-password.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AddcampaignComponent } from './views/addcampaign/addcampaign.component';
import { BrandregistrationpageComponent } from './views/brandregistrationpage/brandregistrationpage.component';
import { DataResolver} from './utils/dataresolver';
import {RouteResolver} from './utils/routeresolverwithnewheader'
import { environment } from 'src/environments/environment';
import {AuthGuard } from '../app/utils/auth.guard'
import { HelpsupportComponent } from './views/helpsupport/helpsupport.component';
import { MessageretargetingComponent } from './views/messageretargeting/messageretargeting.component';
import { DealsretargetingComponent } from './views/messageretargeting/dealsretargeting/dealsretargeting.component';
import { TermsAndConditionsComponent } from './views/register/terms-and-conditions/terms-and-conditions.component';
import { PaymentcheckoutComponent } from './views/paymentcheckout/paymentcheckout.component';
import { LandingscreenComponent } from './views/landingscreen/landingscreen.component';
import { PaymenthistoryComponent } from './views/paymenthistory/paymenthistory.component';
import { canDeactivateGuard } from './utils/can-deactivate.guard';


const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'merchantregister',
    component: BrandregistrationpageComponent,
    resolve: {
      resolvedData: RouteResolver
    },
    data: {
      endpoint: environment.authUrl + 'orchestration-service/event/master/countries' // Specify the API endpoint to resolve
      // Or service: YourDataService.getData // Specify a service method to resolve
    }
  },
  {
    path: 'register/administatordetails',
    component: AdministatordetailsComponent
  },
  {
    path: 'summary',
    component: AdministatorpwdsetComponent
  },
  {
    path: '',
    component: LandingscreenComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'terms',
    component: TermsAndConditionsComponent
  },
  {
    path: 'forgetPassword',
    component: ForgetPasswordComponent
  },
  
  {
    path: 'createPassword',
    component: CreatePasswordComponent
  },
  {
    path: 'userRole',
    component: UserRolesComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./views/dashboard/dashboard.module').then((m) => m.DashboardModule)
      // },
      {
        path: 'approval',
        loadChildren: () => import('../app/approvaldashboard/approvaldashboard.module').then(m => m.ApprovaldashboardModule)
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: {
          resolvedData: DataResolver
        },
        data: {
          endpoint: environment.authUrl + 'orchestration-service/admin/category/all' // Specify the API endpoint to resolve
          // Or service: YourDataService.getData // Specify a service method to resolve
        }
      },
      // {
      //   path: 'dashboard/:isComingFromSource',
      //   component: DashboardComponent
      // },
      {
        path: 'campaign',
        component: AddcampaignComponent,
        canActivate: [AuthGuard],
        canDeactivate: [canDeactivateGuard],
        // resolve: { data: DataResolver }
        resolve: {
          resolvedData: DataResolver
        },
        data: {
          endpoint: environment.authUrl + 'orchestration-service/event/master/countries' // Specify the API endpoint to resolve
          // Or service: YourDataService.getData // Specify a service method to resolve
        }
      },
      {
        path: 'add-campaign',
        component: AddcampaignComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'campaign/target',
        component: TragetaudienceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        component: PaymenthistoryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'campaign/budget',
        component: BudgetdetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'campaign/validatstatus',
        component: ValidstatusComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'companyProfile',
        component: CompanyProfileComponent,
        resolve: {
          resolvedData: DataResolver
        },
        data: {
          endpoint: environment.authUrl + 'orchestration-service/event/master/countries' // Specify the API endpoint to resolve
          // Or service: YourDataService.getData // Specify a service method to resolve
        }
      },
      {
        path: 'companyProfile/details',
        component: AdministratorDetailsComponent
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent
      },
      {
        path: 'usermanagement',
        component: UserManagementComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'help',
        component: HelpsupportComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'reports',
        component: ReportComponent,
        canActivate: [AuthGuard]
      },    
      {
        path: 'reports/detailedReport/:id',
        component: DetailedReportComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'messaging',
        component: MessageretargetingComponent,
        canActivate: [AuthGuard],
        resolve: {
          resolvedData: DataResolver
        },
        data: {
          endpoint: environment.authUrl + 'orchestration-service/event/master/countries' // Specify the API endpoint to resolve
          // Or service: YourDataService.getData // Specify a service method to resolve
        }
      },
      {
        
        path: 'messaging/retargeting/:id',
        component: DealsretargetingComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'messaging/birthday',
        component: BirthdayComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'messaging/festive',
        component: FestiveComponent,
        canActivate: [AuthGuard]
      }, 
      {
        path: 'messaging/birthday/composeMessage',
        component: ComposeMessageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'messaging/festive/composeMessage',
        component: ComposeMessageFestiveComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking'
      // relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
