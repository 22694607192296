<form [formGroup]="addInviteUserForm">
  <div class="div">
    <div class="div-2">
      <div class="div-3">Add & Invite User</div>
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>
    <div class="mt-3 col-12">
      <label class="asterix">Full name</label>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4619d734-1a46-4c52-a05b-f9a2bdcddec2?"
      class="img-2" /> -->
    </div>
    <div class="div-6">
      <input maxlength="50" minlength="2" type="text" class="col-12 form-control" formControlName="fullName" placeholder="Enter full name"
        [ngClass]="{ 'is-invalid': f['fullName'].errors && (f['fullName'].dirty || f['fullName'].touched )}" required />
      @if ( f['fullName'].errors ) {
      <div class="invalid-feedback">
        @if (f['fullName'].errors['required']) {
        <div>Full name is required</div>
        }
        @if (f['fullName'].errors['minlength']) {
          <div>Minimun 2 chars required</div>
          }
        @if (f['fullName'].errors['maxlength']) {
        <div>Full Name must not exceed 50 characters</div>
        }
        @if (f['fullName'].errors['pattern']) {
        <div>Alphanumeric characters (letters and numbers)</div>
        }
      </div>
      }
    </div>
    <div class="mt-3 col-12">
      <label class="asterix">Email</label>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1b20e9ef-888e-40ef-96a1-695cd189dec3?"
      class="img-3" /> -->
    </div>
    <div class="div-9">
      <input class="form-control" type="text" placeholder="Enter your email" autocomplete="email"
        formControlName="email"
        [ngClass]="{ 'is-invalid': f['email'].errors && (f['email'].dirty || f['email'].touched ) }"
        pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
      @if ( f['email'].errors) {
      <div class="invalid-feedback">
        @if (f['email'].errors['required']) {
        <div>Email is required</div>
        }
        @if (f['email'].errors['pattern']) {
        <div>Invalid email !</div>
        }
      </div>
      }
    </div>
    <div class="mt-3 col-12">

      <!-- <div class="col-3 form-group ml-12">
        <div class="custom-select">
            <label class="asterix" for="countrySelect">Country Code</label>
            <select class="mt-2 form-control" formControlName="code" id="code">
                <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
                    {{option.phonePrefixWithCountryName}}</option>
            </select>
        </div>
    </div> -->
      <label class="col-4 asterix" for="countrySelect">Country Code</label>
      <label class="col-4 asterix">Mobile number</label>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/479c8608-9cef-4607-86a3-3c7e7855b0b8?"
      class="img-4" /> -->
    </div>
    <div class="div-12 row">
      <span class="col-4">
        <select class="mt-2 form-control" formControlName="code" id="code">
          <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
            {{option.phonePrefixWithCountryName}}</option>
        </select>
        <svg style="float: inline-end;
        margin-top: -23px;" xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
          <path fill="black" d="m7 10l5 5l5-5z" />
        </svg>
      </span>
      <span class="col-6 mt-2">

        <input class="form-control" type="number" placeholder="Enter mobile number"
          onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
          formControlName="phone" pattern="^[0-9]{8,10}$" onKeyPress="if(this.value.length==12) return false;"
          [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }" required />

        @if ( f['phone'].errors) {
        <div class="invalid-feedback">
          @if (f['phone'].errors['required']) {
          <div>Phone number is required</div>
          }
          @if (f['phone'].errors['pattern']) {
          <div>Invalid number!!</div>
          }
          <!-- @if (f['phone'].errors['pattern']) {
  <div>Invalid Email!!</div>
  } -->
        </div>
        }
      </span>
    </div>
    <div class="mt-3 col-12 hide">
      <label class=" asterix">Assign Role</label>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4d4acef2-46b1-4ad4-b3f5-5300fe8aae66?"
      class="img-5" /> -->
    </div>
    <div class="div-15 hide" >
      <mat-radio-group aria-label="Select an option" class="" formControlName="role">
        <mat-radio-button  (mouseenter)="showAdminData = true" (mouseleave)="showAdminData = false"
          value="1">Administrator</mat-radio-button>
        <mat-radio-button [disabled]="true" (mouseenter)="showMarketingData = true" (mouseleave)="showMarketingData = false"
          value="2">Marketing</mat-radio-button>
        <mat-radio-button [disabled]="true" (mouseenter)="showReportingData = true" (mouseleave)="showReportingData = false"
          value="5">Reporting</mat-radio-button>
        <mat-radio-button [disabled]="true" (mouseenter)="showBillingData = true" (mouseleave)="showBillingData = false"
          value="6">Billing</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="container" *ngIf="showAdminData">
      <div class="tooltiptext">
        <div class="col-12 pb-2 div-24 row">
          <div class="col-2 form-group">
            <label class="">View Campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">Upload /edit campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Report</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Billing</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">View User Management</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

        </div>


      </div>
    </div>

    <div class="container" *ngIf="showMarketingData">
      <div class="tooltiptext">
        <div class="col-12 pb-2 div-24 row">
          <div class="col-2 form-group">
            <label class="">View Campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">Upload /edit campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Report</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Billing</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">View User Management</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

        </div>


      </div>
    </div>

    <div class="container" *ngIf="showReportingData">
      <div class="tooltiptext">
        <div class="col-12 pb-2 div-24 row">
          <div class="col-2 form-group">
            <label class="">View Campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">Upload /edit campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Report</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Billing</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">View User Management</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

        </div>


      </div>
    </div>

    <div class="container" *ngIf="showBillingData">
      <div class="tooltiptext">
        <div class="col-12 pb-2 div-24 row">
          <div class="col-2 form-group">
            <label class="">View Campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">Upload /edit campaign</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Report</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-2 form-group">
            <label class="">View Billing</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
                class="img-14" />
              <div class="div-33">Yes</div>
            </span>
          </div>

          <div class="col-3 form-group">
            <label class="">View User Management</label>
            <span class="d-flex pt-4">
              <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/491d4136b2ea38cc58cb608fabdf84e7cfab4e7621748bcc2823016dc14c9334?apiKey=066d4c2d86f0422b95ab5726d5085658&"
                class="img-14" />
              <div class="div-33">No</div>
            </span>
          </div>

        </div>


      </div>
    </div>


    <!-- <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2286db06-318f-4a92-9723-f1fcb6b58696?"
      class="img-10"
    /> -->
    <!-- <div class="div-24">
      <div class="div-25">
        <div class="div-26">View Campaign details</div>
        <div class="div-27">Upload /edit campaign</div>
        <div class="div-28">View Report</div>
        <div class="div-29">View Billing</div>
      </div>
      <div class="div-30">
        <div class="div-31">
          <div class="div-32">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d9281aef-9fc6-4fc1-9262-aa30406a7f03?"
              class="img-11"
            />
            <div class="div-33">Yes</div>
          </div>
        </div>
        <div class="div-34">
          <div class="div-35">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a563ad92-b579-403b-8968-61afb0784c2a?"
              class="img-12"
            />
            <div class="div-36">Yes</div>
          </div>
        </div>
        <div class="div-37">
          <div class="div-38">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/616fc953-7adb-467b-91b9-f6deee38eca5?"
              class="img-13"
            />
            <div class="div-39">Yes</div>
          </div>
        </div>
        <div class="div-40">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/78d11bec-5e77-4ee6-906f-cbf8b8104797?"
            class="img-14"
          />
          <div class="div-41">Yes</div>
        </div>
      </div>
    </div> -->
    <!-- <div class="div-42">
      <div class="div-43 asterix">Countries to be operational</div>
     
    </div> -->
    <!-- <div class="div-44">

        <ng-multiselect-dropdown style="padding: 0;border: 0;" [settings]="dropdownSettings" class="form-control"
          [placeholder]="'Select Country'" [data]="countryData" bindLabel="country_name" bindValue="country_id"
          formControlName="country" required>
        </ng-multiselect-dropdown>
         
    </div> -->
    <div class="mt-3 col-12">
      <label class=" asterix">Custom message</label>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8a2e989c-c550-4e45-a15a-74d1992934e3?"
      class="img-20" /> -->
    </div>
    <div class="div-54">
      <textarea class="form-control" rows="2" formControlName="message" placeholder="Enter message here"
        [ngClass]="{ 'is-invalid':  f['message'].errors  && (f['message'].dirty || f['message'].touched ) }"
        required></textarea>
      @if ( f['message'].errors) {
      <div class="invalid-feedback">
        @if (f['message'].errors['required']) {
        <div>Message is required</div>
        }
      </div>
      }
    </div>
    <div class="div-55">
      <button class=" btn btn-secondary" (click)="cancel()">Cancel</button>
      <button class="btn div-57" [disabled]="addInviteUserForm.invalid" (click)="add()">Add & send invite</button>
    </div>
  </div>
</form>