import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';

interface TableRow {
  dealId: number;
  dealTitle: string;
  validTo: string;
  status: string;
  currentHighProspects: number;
  messagesSent: number;
  currentPendingProspects: number;
  totalSpent: number;
  originalData: any;  // This will hold the entire original object
}

@Component({
  selector: 'app-paymenthistory',
  templateUrl: './paymenthistory.component.html',
  styleUrl: './paymenthistory.component.scss'
})


export class PaymenthistoryComponent implements OnInit {

 
  
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  reportPlaceHolders:any;

  userData:any;
  paymentTotalData:any;


  activePage: number = 1;
  displayedColumns: string[] = ['date','email', 'name', 'amount', 'status',  'description', 'display_brand'];
  dataSource = new MatTableDataSource<TableRow>();
  totalCount: number = 0;
  currentPage: any;
  pageSize: number = 10;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private dashboardService:DashboardService, private cStorage: ClientSideStorageService){
    this.placeHolders = this.placeholders['Messaging_Page']
    this.reportPlaceHolders = this.placeholders['Reports_page']

    this.userData = JSON.parse(this.cStorage.get("marketplaceData"));

    const today = new Date().toISOString().split('T')[0]; // Formats to YYYY-MM-DD
    this.fromDate = today;
    this.toDate = today;
  }

  ngOnInit(){

this.getPaymentData()
  }

  page:any;
  fromDate!: string;
  toDate!: string;
  selectedDate!: string;
  getPaymentData(){




const payload = {
  fromDate: this.fromDate,
  toDate: this.toDate,
  selectedDate: this.selectedDate
};
    const fromDate = this.formatDate(this.fromDate)
    const toDate =this.formatDate(this.toDate)
    this.page = this.activePage -1
    
    // this.dashboardService.paymentHistoryData(fromDate, toDate, this.userData.marketplace_id).subscribe((res: any) => {
    //   this.paymentTotalData = res.body.data.content;
    //   console.log(this.paymentTotalData)
    //   this.dataSource = this.paymentTotalData;
    //   this.totalCount = res.body.data.totalElements;
    //   this.currentPage = this.activePage;
    // });

    this.dashboardService.paymentHistoryData(fromDate, toDate, this.userData.marketplace_id,this.page).subscribe((res: any) => {
      this.paymentTotalData = res.body.data.content;
    
      // Select only specific fields
      this.dataSource = this.paymentTotalData.map((item: any) => ({
        date: item.created_date,
        name: item.deal_name,
        amount: item.amount,
        status: item.status,
        email:item.deal_uuid,
        type:item.type,
        description:item.description,
        display_brand:item.display_brand
        // Add other required fields here
      }));
    
      this.totalCount = res.body.data.totalElements;
      this.currentPage = this.activePage;
    });
    
  }


  recordTotalCount: any;
  tableData: any;
  filterValue:any;
 
  // loadTableData(param:any) {
  //   let data = {
  //     keywords: [],
  //     pagination: this.activePage,
  //     filterStatus: param
  //   }
  //   const fromDate ='07/19/2024'
  //   const toDate ='10/19/2024'
  //   this.dashboardService.paymentHistoryData(fromDate, toDate, this.userData.marketplace_id)
  //     .pipe(
  //       map((res: any) => {
  //         this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
  //         return res?.body?.data?.FAVORITED_DEALS.map((item: any) => ({
  //           dealId: item.deal.product_id,
  //           dealTitle: item.deal.product_name,
  //           validTo: item.deal.available_to,
  //           status: item.deal.status,
  //           currentHighProspects: item.details.totalUniqueProspects,
  //           messagesSent: item.details.totalCustomerOfferMade,
  //           currentPendingProspects: item.details.totalPendingProspects,
  //           totalSpent: item.details.totalSpend,
  //           originalData: item  // Store the entire original item
  //         }));
  //       })
  //     )
  //     .subscribe((transformedData: TableRow[]) => {
  //       this.tableData = transformedData;
  //       this.dataSource = this.tableData;
  //       this.totalCount = this.recordTotalCount;
  //       this.currentPage = this.activePage;
  //       // You can now use this.tableData as the data source for your table
  //     });
  // }


  private formatDate(date: string): string {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  onPageChange(page: number): void {
    this.activePage = page;
    this.getPaymentData();
  }

  onRowClicked(row: TableRow) {

  }

  
  applyFilter(event: Event) {
  }

  getStatusClass(status: string): any {
    switch(status) {
      case 'succeeded': return 'active-chip';
      case 'requires_capture': return 'pending-chip';
      case 'E': return 'expired-chip';
      case 'I': return 'inactive-chip';
      case 'D': return 'draft-chip';
      case 'U': return 'upcoming-chip';
      case 'S': return 'paused-chip';
      case 'R': return 'rejected-chip';
      case 'N': return 'new-chip';
      case 'C': return 'completed-chip';
      default: return '';
    }
  }
  
  getStatusText(status: string): any {
    switch(status) {
      case 'succeeded': return 'succeess';
      case 'requires_capture': return 'Authorized';
      case 'E': return this.reportPlaceHolders['LBL_EXPIRED'];
      case 'I': return this.reportPlaceHolders['LBL_INACTIVE'];
      case 'D': return this.reportPlaceHolders['LBL_DRAFT'];
      case 'U': return this.reportPlaceHolders['LBL_UPCOMING'];
      case 'S': return this.reportPlaceHolders['LBL_PAUSED'];
      case 'R': return this.reportPlaceHolders['LBL_REJECTED'];
      case 'N': return this.reportPlaceHolders['LBL_NEW'];
      case 'C': return this.reportPlaceHolders['LBL_COMPLETED'];
      default: return 'Unknown';
    }
  }

}
