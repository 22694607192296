import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-previewdealwidget',
  templateUrl: './previewdealwidget.component.html',
  styleUrl: './previewdealwidget.component.scss'
})

export class PreviewdealwidgetComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  dealPreviewImg:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageSrc: string }) { 
    this.dealPreviewImg = this.placeholders['locale'] === 'en' ?'../../../../assets/images/PreviewEn.png':'../../../../assets/images/PreviewFr.png'
  }


}
