import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgScrollbarModule } from 'ngx-scrollbar';
// Import routing module
import { AppRoutingModule } from './app-routing.module';
// Import app component
import { AppComponent } from './app.component';
// Import containers
import { DefaultFooterComponent, DefaultHeaderComponent, DefaultLayoutComponent } from './containers';
import { MatIconModule } from '@angular/material/icon';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";

import { TranslationService } from './services/translation.service';


export function initializeApp(translationService: TranslationService) {
  return () => translationService.initializePlaceholders();
}


import {
  AvatarModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  DropdownModule,
  FooterModule,
  FormModule,
  GridModule,
  HeaderModule,
  ListGroupModule,
  NavModule,
  PaginationModule,
  ProgressModule,
  SharedModule,
  SidebarModule,
  TabsModule,
  UtilitiesModule,
} from '@coreui/angular';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AdministatordetailsComponent } from './views/register/administatordetails/administatordetails.component';
import { AdministatorpwdsetComponent } from './views/register/administatorpwdset/administatorpwdset.component';
import { CampaignComponent } from './views/campaign/campaign.component';
import { TragetaudienceComponent } from './views/campaign/tragetaudience/tragetaudience.component';
import { BudgetdetailsComponent } from './views/campaign/budgetdetails/budgetdetails.component';
import { ValidstatusComponent } from './views/campaign/validstatus/validstatus.component';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { AddinviteuserComponent } from './views/user-management/addinviteuser/addinviteuser.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ReportComponent } from './views/report/report.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import { FilterReportComponent } from './views/report/filter-report/filter-report.component';
import { DetailedReportComponent } from './views/report/detailed-report/detailed-report.component';
import { MatRadioModule } from '@angular/material/radio';
import { MessagingComponent } from './views/messaging/messaging.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BirthdayComponent } from './views/messaging/birthday/birthday.component';
import { FestiveComponent } from './views/messaging/festive/festive.component';
import { FavouritesComponent } from './views/messaging/favourites/favourites.component';
import { FilterFavouriteCampaignsComponent } from './views/messaging/favourites/filter-favourite-campaigns/filter-favourite-campaigns.component';
import { SelectUsersComponent } from './views/messaging/favourites/select-users/select-users.component';
import { SelectUsersPopupComponent } from './views/messaging/favourites/select-users-popup/select-users-popup.component';
import { SelectUsersFilterComponent } from './views/messaging/favourites/select-users-filter/select-users-filter.component';
import { ComposeMessageComponent } from './views/messaging/birthday/compose-message/compose-message.component';
import { ComposeMessageFestiveComponent } from './views/messaging/festive/compose-message-festive/compose-message-festive.component';
import { FilterCampaignComponent } from './views/dashboard/filter-campaign/filter-campaign.component';
import { UserRolesComponent } from './views/user-roles/user-roles.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AgGridModule } from "ag-grid-angular";
import { ViewComponent } from './views/sharedComponent/view/view.component';
import { EditComponent } from './views/sharedComponent/edit/edit.component';
import { SwitchToggleComponent } from './views/sharedComponent/switch-toggle/switch-toggle.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PaginationComponent } from './views/sharedComponent/pagination/pagination.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';
import { CreatePasswordComponent } from './views/login/create-password/create-password.component';
import { CompanyProfileComponent } from './views/company-profile/company-profile.component';
import { AdministratorDetailsComponent } from './views/company-profile/administrator-details/administrator-details.component';
import { ConfirmationDialogComponent } from './views/sharedComponent/confirmation-dialog/confirmation-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { ToastrModule } from "ngx-toastr";
import { NgOtpInputModule } from  'ng-otp-input';
import { CheckboxComponent } from './views/sharedComponent/checkbox/checkbox.component';
import { TokenStorage } from "./token.storage";
import { SessionStorageService } from "ngx-webstorage";
import { CookieService } from "ngx-cookie-service";
import { AuthInterceptor } from "./authentication.interceptor";
import { ChangePasswordComponent } from './views/company-profile/change-password/change-password.component';
import { LogoutComponent } from './views/logout/logout.component';
import { AddcampaignComponent } from './views/addcampaign/addcampaign.component';
import { BrandregistrationpageComponent } from './views/brandregistrationpage/brandregistrationpage.component';
import { TermsAndConditionsComponent } from './views/register/terms-and-conditions/terms-and-conditions.component';
import { PasswordRulesComponent } from './views/sharedComponent/password-rules/password-rules.component';
import { AsyncPipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CreateflashdealComponent } from './views/sharedComponent/createflashdeal/createflashdeal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HelpsupportComponent } from './views/helpsupport/helpsupport.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MessageretargetingComponent } from './views/messageretargeting/messageretargeting.component';
import { DealsretargetingComponent } from './views/messageretargeting/dealsretargeting/dealsretargeting.component';
import { PreviewdealwidgetComponent } from './views/sharedComponent/previewdealwidget/previewdealwidget.component';
import { PaymentcheckoutComponent } from './views/paymentcheckout/paymentcheckout.component';
import { LandingscreenComponent } from './views/landingscreen/landingscreen.component';
import { PaymenthistoryComponent } from './views/paymenthistory/paymenthistory.component';
import { DealcreationComponent } from './views/dealcreation/dealcreation.component';
import { PaymentsummaryComponent } from './views/paymentsummary/paymentsummary.component';

const APP_CONTAINERS = [
  DefaultFooterComponent,
  DefaultHeaderComponent,
  DefaultLayoutComponent
];

@NgModule({
  declarations: [AppComponent, ...APP_CONTAINERS,
    LoginComponent,
    RegisterComponent,
    AdministatordetailsComponent,
    AdministatorpwdsetComponent,
    CampaignComponent,
    TragetaudienceComponent,
    BudgetdetailsComponent,
    ValidstatusComponent,
    UserManagementComponent,
    AddinviteuserComponent,
    DashboardComponent,
    ReportComponent,
    FilterReportComponent,
    DetailedReportComponent,
    MessagingComponent,
    BirthdayComponent,
    FestiveComponent,
    FavouritesComponent,
    FilterFavouriteCampaignsComponent,
    SelectUsersComponent,
    SelectUsersPopupComponent,
    SelectUsersFilterComponent,
    ComposeMessageComponent,
    ComposeMessageFestiveComponent,
    FilterCampaignComponent,
    UserRolesComponent,
    ViewComponent,
    EditComponent,
    SwitchToggleComponent,
    PaginationComponent,
    ForgetPasswordComponent,
    CreatePasswordComponent,
    CompanyProfileComponent,
    AdministratorDetailsComponent,
    ConfirmationDialogComponent,
    CheckboxComponent,
    ChangePasswordComponent,
    LogoutComponent,
    AddcampaignComponent,
    TermsAndConditionsComponent,
    BrandregistrationpageComponent,
    PasswordRulesComponent,
    CreateflashdealComponent,
    HelpsupportComponent,
    MessageretargetingComponent,
    DealsretargetingComponent,
    PreviewdealwidgetComponent,
    PaymentcheckoutComponent,
    LandingscreenComponent,
    PaymenthistoryComponent,
    DealcreationComponent,
    PaymentsummaryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AvatarModule,
    BreadcrumbModule,
    FooterModule,
    DropdownModule,
    GridModule,
    HeaderModule,
    SidebarModule,
    IconModule,
    NavModule,
    ButtonModule,
    FormModule,
    UtilitiesModule,
    ButtonGroupModule,
    ReactiveFormsModule,
    FormsModule,
    SidebarModule,
    SharedModule,
    TabsModule,
    ListGroupModule,
    ProgressModule,
    BadgeModule,
    ListGroupModule,
    CardModule,
    NgScrollbarModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    HttpClientModule,
    AgGridModule,
    NgSelectModule ,
    NgMultiSelectDropDownModule.forRoot(),
    PaginationModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatTooltipModule,
    MatIconModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule ,
    ReactiveFormsModule,
    AsyncPipe,
    NgOtpInputModule,
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    ToastrModule.forRoot(
      {
        timeOut: 3500,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }
    ),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }, 
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    IconSetService,
    Title,
    TokenStorage,
    SessionStorageService,
    CookieService,
    TranslationService,
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [TranslationService], multi: true },

  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
