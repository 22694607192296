import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private paymentSuccessSource = new Subject<any>();
  private paymentErrorSource = new Subject<string>();

  private apiResponse = new BehaviorSubject<any>(null);

  paymentSuccess$ = this.paymentSuccessSource.asObservable();
  paymentError$ = this.paymentErrorSource.asObservable();

  apiResponse$ = this.apiResponse.asObservable();

  emitPaymentSuccess(paymentDetails: any) {
    this.paymentSuccessSource.next(paymentDetails);
  }

  emitPaymentError(errorMessage: string) {
    this.paymentErrorSource.next(errorMessage);
  }

  updateApiResponse(data: any) {
    this.apiResponse.next(data);
  }

  resetApiResponse() {
    this.apiResponse.next(null);
  }
}