<mat-card  style="padding: 10px;">
    <div class="col-12 row">

        <div class="col-1">
            <button (click)="back()" class="btn btn-primary btn-block">Back</button>
        </div>
        <div class="col-5 right-border p-3">
            <h3 style="justify-content: center;">Summary</h3><br>
            <span class="pt-3"><b>Deal Title</b>: {{data.deal_name}}</span><br>
            <span><b>Start: </b> {{data.startDate}}</span> | <span><b>End:</b> {{data.endDate}}</span>

            <!-- <h5 class="pt-3">Budget : {{data.billAmount}}</h5> -->
            <!-- <div className=" p-4 space-y-2" style="background: #e3e3e3;border-radius: 6px;">
               
                <p style="margin-left: 10px;" className="text-sm text-muted-foreground">2.9% + $0.30 for online credit
                    card transactions in the
                    U.S.</p>
            </div> -->
            <div class="pt-3" style="background: #b1d488;
            border-radius: 10px;
            width: fit-content;
            padding-right: 10px;">
                <h4 style="margin-top: -10px;margin-left: 10px;">Total Cost : {{data.billAmount}}</h4>
            </div>



            <div class="pt-3">
                <img *ngIf="data.imagePreview; else dealImage" [src]="data.imagePreview" alt="Image"
                    style="max-width: 300px; max-height: 300px;" />

                <!-- Fallback content if imagePreview is null -->
                <ng-template #dealImage>
                    <img [src]="data.brandimage" alt="Image" style="max-width: 300px; max-height: 300px;" />
                    <!-- You can also show a default image -->
                    <!-- <img src="assets/default-image.png" alt="Default Image" style="max-width: 300px; max-height: 300px;" /> -->
                </ng-template>
            </div>
        </div>

        <div class="col-6 p-3">
            <app-paymentcheckout [amount]="data.billAmount" [currency]="data.billCurrency" [description]="data.billDescription"
                [brandId]="data.brandId" [dealId]="data.dealId" [postOfficeId]="data.postOfficeId" [deal_name]="data.deal_name" 
                [type]="data.type" [deal_uuid]="data.deal_uuid" [deal_budget]="data.deal_budget" [useremail]="data.useremail" [brandname]="data.brandname">
            </app-paymentcheckout>
        </div>
    </div>
    <span class="notePay">Note: This amount will be on hold and will be on deal completion</span>
</mat-card>
