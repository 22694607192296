<div class="step-container">
    <!-- Step Titles -->
    <div class="steps row text-center">
      <div class="col" [ngClass]="{ active: currentStep === 1 }" (click)="setStep(1)">Deal Details</div>
      <div class="col" [ngClass]="{ active: currentStep === 2 }" (click)="setStep(2)">Target Audience</div>
      <div class="col" [ngClass]="{ active: currentStep === 3 }" (click)="setStep(3)">Pricing Details</div>
      <div class="col" [ngClass]="{ active: currentStep === 4 }" (click)="setStep(4)">Budget Details</div>
    </div>
  
    <!-- Step Content -->
    <div class="step-content">
      <!-- Deal Details Form -->
      <div *ngIf="currentStep === 1">
        <h3>Deal Details</h3>
        <form [formGroup]="dealForm" class="container" (ngSubmit)="onSubmit()" novalidate>
            <div class="row">
              <!-- Left Column -->
              <div class="col-md-6">
                <!-- Deal Title -->
                <label>Deal Title</label>
                <mat-form-field appearance="outline" class="w-100">
                 
                  <input matInput formControlName="dealTitle" maxlength="50" />
                  <mat-hint align="end">{{ dealTitle?.value?.length || 0 }}/50</mat-hint>
                  <mat-error *ngIf="dealTitle?.hasError('required')">Title is required</mat-error>
                </mat-form-field>
          
                <!-- Deal Categories -->
                <label>Deal Categories</label>
                <mat-form-field appearance="outline" class="w-100">
               
                  <mat-select formControlName="dealCategory">
                    <mat-option *ngFor="let category of dealCategories" [value]="category">
                      {{ category }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="dealForm.get('dealCategory')?.hasError('required')">Category is required</mat-error>
                </mat-form-field>
              </div>
          
              <!-- Right Column -->
              <div class="col-md-6">
                <!-- Discount Code -->
                <label>Discount Code</label>
                <mat-form-field appearance="outline" class="w-100">
                 
                  <input matInput formControlName="discountCode" maxlength="10" />
                  <mat-error *ngIf="discountCode?.hasError('required')">Discount code is required</mat-error>
                </mat-form-field>
          
                <!-- Discount Type -->
                <label>Discount Type</label>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-select formControlName="discountType">
                    <mat-option *ngFor="let type of discountTypes" [value]="type">
                      {{ type }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="dealForm.get('discountType')?.hasError('required')">Type is required</mat-error>
                </mat-form-field>
              </div>
            </div>
          
            <!-- Discount Description -->
            <div class="row">
              <div class="col-12">
                <label>Deal Description</label>
                <mat-form-field appearance="outline" class="w-100">
                
                  <textarea matInput formControlName="discountDescription" maxlength="100"></textarea>
                  <mat-hint align="end">{{ discountDescription?.value?.length || 0 }}/100</mat-hint>
                </mat-form-field>
              </div>
            </div>
          
            <!-- Submit Button -->
            <div class="row mt-3">
              <div class="col-12">
                <button mat-raised-button color="primary" type="submit" [disabled]="dealForm.invalid">Submit</button>
              </div>
            </div>
          </form>
          
      </div>
  
      <!-- Placeholder content for other steps -->
      <div *ngIf="currentStep === 2">
        <h3>Target Audience</h3>
        <!-- Add form fields for Target Audience here -->
      </div>
      <div *ngIf="currentStep === 3">
        <h3>Pricing Details</h3>
        <!-- Add form fields for Pricing Details here -->
      </div>
      <div *ngIf="currentStep === 4">
        <h3>Budget Details</h3>
        <!-- Add form fields for Budget Details here -->
      </div>
    </div>
  </div>
  