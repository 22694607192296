import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent implements ICellRendererAngularComp {
  params: any;
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;

  constructor() {
    this.placeHolders = this.placeholders['dashboard_page']
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  edit(e:any) {
    return this.params.clicked(this.params.data);
  }
}
