import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilService {
  width: any;
  private registerationData = new BehaviorSubject<any>([]);
  private registerData = new BehaviorSubject<any>([]);
  private loginData = new BehaviorSubject<any>([]);
  private adminData = new BehaviorSubject<any>([]);
  private postofficeLogoData = new BehaviorSubject<any>([]);
  private passwordRulesData = new Subject<string>();
  value$ = this.passwordRulesData.asObservable();
  userId: any;

  constructor(private http: HttpClient, private clientStorage: ClientSideStorageService,) {
    this.userId = JSON.parse(this.clientStorage.get("userId"));
  }

  getColumnHeader(field: any, headerName: any) {
    let obj = {
      field: undefined,
      headerName: null,
      tooltipField: null,
      headerTooltip: null
    };
    obj['field'] = field;
    obj['headerName'] = headerName;
    obj['tooltipField'] = field;
    obj['headerTooltip'] = headerName;
    return obj;
  }

  getDefaultColumnDef() {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      tooltipShowDelay: 0,
      autoHeight: false,
    }
  }

  getCommaSeparatedFilter() {
    return [
      'contains',
      'notContains',
      'equals',
      'notEqual',
      'startsWith',
      'endsWith',
      {
        displayKey: 'commaSeparated',
        displayName: 'Comma Separated',
        test: (filterValue: any, cellValue: any) => {
          return filterValue.split(/\s*,\s*/).some(function (v: any) { return v && v.toLowerCase() == cellValue });
        }
      },

    ]
  }

  getCommaSeparatedFilterWithSpaces() {
    return [
      {
        displayKey: 'commaSeparated',
        displayName: 'Comma Separated',
        test: (filterValue: any, cellValue: any) => {
          return filterValue.includes(cellValue);
        }
      },
      'contains',
      'notContains',
      'equals',
      'notEqual',
      'startsWith',
      'endsWith',

    ]
  }

  getFilterButtons() {
    return {
      buttons: ['cancel', 'clear'],
      closeOnApply: true,
    }
  }

  setLoginData(param: any) {
    this.loginData.next(param);
  }

  getLoginData() {
    return this.loginData.asObservable();
  }

  setData(param: any): void {
    this.registerData.next(param);
  }

  getData(): Observable<any> {
    return this.registerData.asObservable();
  }

  sendEmailOtp(data: any, country?: any, macAddress?: any) {
    const url = environment.authUrl + 'orchestration-service/admin/send-otp-email';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  sendMobileOtp(data: any, country?: any, macAddress?: any) {
    const url = environment.authUrl + 'orchestration-service/event/send-otp';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  ValidateOtp(data: any, country?: any, macAddress?: any) {
    const url = environment.authUrl + 'orchestration-service/event/validate-otp';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  adminSignup(data: any, country?: any, macAddress?: any) {
    const url = environment.authUrl + 'orchestration-service/admin/user/signup';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  getBusinessTypes(country?: any, macAddress?: any): Observable<any> {
    const translations = localStorage.getItem('userLocale');
    const url = translations === 'fr'?environment.authUrl + 'orchestration-service/admin/business-type/all' + '?locale=FR' : environment.authUrl + 'orchestration-service/admin/business-type/all';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };
    return this.http.get(url, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  getCountry(country: any, macAddress: any): Observable<any> {
    const url = environment.authUrl + 'orchestration-service/event/master/countries';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };
    return this.http.get(url, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  getState(countryid: any, country: any, macAddress: any): Observable<any> {
    ///orchestration-service/event/master/states?country_id=4
    // const url = environment.authUrl+'orchestration-service/event/master/states?country_id=' + countryid;

    const translations = localStorage.getItem('userLocale');
    const url = translations === 'fr' ? environment.authUrl + 'orchestration-service/event/master/states?country_id=' + countryid + '&locale=FR' : environment.authUrl + 'orchestration-service/event/master/states?country_id=' + countryid;

    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };
    return this.http.get(url, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  getCity(countryCode: any, stateCode: any, country: any, macAddress: any): Observable<any> {
    //orchestration-service/event/master/cities?state_code=PR
    const translations = localStorage.getItem('userLocale');
    const url = translations === 'fr' ? environment.authUrl + 'orchestration-service/event/master/cities?country_id=' + countryCode + '&state_code=' + stateCode + '&locale=FR' : environment.authUrl + 'orchestration-service/event/master/cities?country_id=' + countryCode + '&state_code=' + stateCode;
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };
    return this.http.get(url, { headers: httpOptions, observe: 'response', withCredentials: true });
  }

  tokenSet(response: any) {
    let authToken = response.headers.get('auth-token');
    if (authToken != undefined && response.body?.data) {
      this.clientStorage.set('token', JSON.stringify(authToken));
    }
  }

  registerCompany(personID: any, data?: any, country?: any, macAddress?: any) {
    const url = environment.authUrl + 'orchestration-service/admin/marketplace/register/' + personID;
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  // registerCompanyAddress(data: any, country?: any, macAddress?: any) {
  //   const url = 'https://dev.ship2myid.com/orchestration-service/admin/marketplace/update';
  //   const httpOptions = {
  //     'userName': 'satheshjkv@gmail.com',
  //     'macAddress': macAddress,
  //     'country': country
  //   };

  //   return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  // }

  uploadImageRegister(data: any) {
    const url = environment.authUrl + 'orchestration-service/admin/image/brand/upload?fileType=jpg';
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }

  uploadDocRegister(data: any) {
    const url = environment.authUrl + 'orchestration-service/admin/document/brand/upload?fileType=pdf';
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }


  editCompanyProfile(data: any, country?: any, macAddress?: any) {
    // this.userId = JSON.parse(this.clientStorage.get("userId")); 
    const url = environment.authUrl + 'orchestration-service/admin/marketplace/update';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      // 'macAddress': macAddress,
      // 'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  getRegisterCompanyDetails() {
    this.userId = JSON.parse(this.clientStorage.get("userId"));
    const url = environment.authUrl + 'orchestration-service/admin/user/marketplace/' + this.userId;
    return this.http.get(url);
  }

  getAllRoles() {
    const url = environment.authUrl + 'orchestration-service/admin/master/roles';
    return this.http.get(url);
  }

  adminDetailsUpdate(data: any) {
    this.userId = JSON.parse(this.clientStorage.get("userId"));
    const url = environment.authUrl + 'orchestration-service/admin/update-user';
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }

  adminPasswdUpdate(data: any) {
    this.userId = JSON.parse(this.clientStorage.get("userId"));
    const url = environment.authUrl + 'orchestration-service/event/admin/change-password';
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }
  adminUpdatePassword(data: any, country?: any, macAddress?: any) {
    this.userId = JSON.parse(this.clientStorage.get("userId"));

    const url = environment.authUrl + 'orchestration-service/event/admin/update-password';
    const httpOptions = {
      // 'userName': 'satheshjkv@gmail.com',
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, withCredentials: true });
  }

  getUserProfileDetails() {
    this.userId = JSON.parse(this.clientStorage.get("userId"));
    const url = environment.authUrl + 'orchestration-service/admin/user/profile?userId=' + this.userId;
    return this.http.get(url);
  }

  setRegistrationData(param: any): void {
    this.registerationData.next(param);
  }

  getRegistrationData(): Observable<any> {
    return this.registerationData.asObservable();
  }

  setAdminData(param: any) {
    this.adminData.next(param);
  }

  getAdminData() {
    return this.adminData.asObservable();
  }

  getPostOfficeLogo(data: any) {
    let url: any;
    url = environment.authUrl + 'orchestration-service/event/addPostOffice/' + data;

    const httpOptions = {
      // 'userName': username,
      // 'macAddress': macAddress,
      // 'country': country
    };

    return this.http.get(url);
  }


  setPostofficeLogoData(param: any) {
    this.postofficeLogoData.next(param);
  }

  getPostofficeLogoData() {
    return this.postofficeLogoData.asObservable();
  }

  setPasswordRulesDataData(param: any) {
    this.passwordRulesData.next(param);
  }
}

@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  programmaticNavigation = false; // Initialize flag to false
}
